/*const lista = document.querySelectorAll('.options');

lista[0].addEventListener('mousemove', function() {
    const submenu = document.querySelector('.sub-menu');
    submenu.classList.add('show');
});

lista[0].addEventListener('mouseout', function() {
    const submenu = document.querySelector('.sub-menu');
    submenu.classList.remove('show');
});*/

const menuMovil = document.querySelector('.menu-movil');
const listMovil = document.querySelector('.list-movil');
const listBack = document.querySelector('.list-back');
const exitMovil = document.querySelector('.exit-menu-movil');
const html = document.querySelector('html');
menuMovil.addEventListener('click', function () {
    listMovil.classList.add('showMenuMovil');
    listBack.classList.add('moveMenuMovil');
    html.style.overflowY = 'hidden';
});

exitMovil.addEventListener('click', function () {
    listMovil.classList.remove('showMenuMovil');
    listBack.classList.remove('moveMenuMovil');
    setTimeout(() => {
        html.style.overflowY = 'visible';
    }, 500);
});

